// 时间戳转日期
export function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('default', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'});
}

// 将ISO 8601格式的日期字符串转换为JavaScript日期对象
export function formattedDate(createdAt) {
    return new Date(createdAt).toLocaleString('zh-CN', {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit', second: '2-digit',
    });
}

export function getTimestamp() {
    return Math.floor(Date.now() / 1000)
}