import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "@/views/index/index.vue";
Vue.use(VueRouter)

const routes = [
    {
        path: '/index',
        name: 'Index',
        component: Index,
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/login.vue')
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/Index.vue')
    },
    {
        path: '*',
        redirect: '/index' // 重定向所有未知路径到首页
    }
]

const router = new VueRouter({
    routes
})


router.beforeEach((to, from, next) => {
    if (to.path === '/admin') {
        const token = localStorage.getItem('token');
        if (token) {
            next();
        } else {
            next('/login');
        }
    } else {
        next();
    }
});

export default router
