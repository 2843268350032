<template>
  <div>
    <div class="head">
      <el-row type="flex" justify="center">
        <el-col :xs="22" :sm="15" :md="15" :lg="12">
          <div class="head-content">
            <div class="left">
             <span>
                <svg class="icon" aria-hidden="true" style="font-size: 30px;">
                  <use xlink:href="#icon-fuwushouquan"></use>
                </svg>
             </span>
              <span style="font-weight: 600;">授权管理系统</span>
            </div>
            <div class="right">
              <el-button size="mini" type="primary" @click="$router.push('login')">登录</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" justify="center">
      <el-col :xs="22" :sm="15" :md="15" :lg="12">
        <div class="main">
          <div class="title">
            <div style="font-size: 30px;font-weight: 600;">在线授权查询</div>
            <div style="color: red;margin-top: 10px;font-size: 14px;">快速查询您的授权情况，举报有奖，盗版必究。</div>
          </div>

          <div class="content">
            <el-input placeholder="请输入你的ID进行查询操作" v-model.trim="id">
              <el-button slot="append" icon="el-icon-search" @click="query" v-loading.fullscreen.lock="fullscreenLoading">查询</el-button>
            </el-input>

            <div style="text-align: start">
              <el-empty v-if="query_result.length === 0" description="暂无数据，授权请联系：3367964245"></el-empty>
              <div v-else style="max-width: 600px;margin: 0 auto;margin-top: 20px;">
                <div v-for="(item, index) in query_result" :key="index" class="text item">
                  <div class="ribbon">正版</div>
                  <div>
                    <span style="font-weight: 600;">{{ item.ProjectName }}</span>
                    <el-divider direction="vertical"></el-divider>
                    <span style="background-color: rgb(229,245,230);color: rgb(56,186,71);border-radius: 20px;font-size: 12px;padding: 2px 4px">已授权</span>
                  </div>
                  <div>
                    <span style="font-size: 14px;">授权到期：{{ formatTimestamp(item.Exp) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {formatTimestamp} from "@/utils/time"

export default {
  data() {
    return {
      fullscreenLoading: false,
      id: "",
      query_result: [],
    }
  },
  methods: {
    formatTimestamp,
    async query() {
      try {
        this.fullscreenLoading = true;
        if (this.id.length < 5) {
          this.$notify.error({
            title: '查询结果',
            message: "此id未授权"
          });
          return
        }
        const response = await this.$request.get('/queryAuth?id=' + this.id)
        if (response.data.error === 0) {
          if (response.data.result && response.data.result.length > 0) {
            this.$notify({
              type: 'success',
              title: '查询结果',
              message: response.data.msg
            });
          } else {
            this.$notify.error({
              title: '查询结果',
              message: "此id未授权"
            });
          }
          this.query_result = response.data.result;
        } else {
          this.$notify.error({
            title: '查询结果',
            message: response.data.msg
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.fullscreenLoading = false;
      }
    }
  }
}
</script>

<style>
body {
  background-color: #f6f6f6;
}
</style>

<style scoped lang="less">
.head {
  background-color: #fff;
  border-bottom: 1px solid #eee;

  .head-content {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .left {
      color: black;
      display: flex;
      gap: 5px;
      cursor: pointer;
      align-items: center;
    }

    .right {
      color: black;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 20px;
      cursor: pointer;
      transition: all ease, color 0.3s ease; // 添加过渡效果
    }

    .right > span:hover {
      color: #1890ff;
    }
  }
}

.main {
  margin-top: 50px;
  border-radius: 10px;
  padding: 20px;
  background-color: white;

  .title {
    margin-bottom: 40px;
    text-align: center;
  }

  .content {
    text-align: center;

    .item {
      border-radius: 10px;
      border: 1px solid #eee;
      padding: 20px;
      margin-bottom: 10px;
      position: relative;
    }

    .ribbon {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
    }
    .ribbon {
      --f: .5em; /* control the folded part */

      position: absolute;
      top: 0;
      right: 0;
      line-height: 1.8;
      padding-inline: 1lh;
      padding-bottom: var(--f);
      border-image: conic-gradient(#0008 0 0) 51%/var(--f);
      clip-path: polygon(
          100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px));
      transform: translate(calc((1 - cos(45deg))*100%), -100%) rotate(45deg);
      transform-origin: 0% 100%;
      background-color: #4caf50; /* the main color  */
    }

  }
}

</style>