import Vue from 'vue'
import App from './App.vue'
import '@/assets/icons/icon'
import '@/assets/css/icon.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import request, { setRouter} from "@/api/api";

Vue.prototype.$request = request

Vue.config.productionTip = false

Vue.use(ElementUI);
// 将 Vue Router 实例传递给设置函数
setRouter(router);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
