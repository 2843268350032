import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    projectOptions: []
  },
  getters: {
    getProjectOptions(state) {
      return state.projectOptions;
    }
  },
  mutations: {
    setProjectOptions(state, options) {
      state.projectOptions = options;
    }
  },
  actions: {
    updateProjectOptions({ commit }, options) {
      commit('setProjectOptions', options);
    }
  },
  modules: {
  }
})
